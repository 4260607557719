import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g28'

let batchAddUrl = 'batchAdd'
let aggregationUrl = 'aggregation'
let tenderStatUrl = 'tenderStats'
let detailTenderStatUrl = 'detailTenderStats'

class G28Service extends RestService {
  batchAdd (g28Models) {
    g28Models.forEach(g28Model => {
      g28Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g28Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getTenderStat (year, month, projectGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, tenderStatUrl), {
      params: {
        year,
        month,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  getDetailTenderStat (year, month, projectGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, detailTenderStatUrl), {
      params: {
        year,
        month,
        projectGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  aggregation (tenderGuid, year, month) {
    return axios.get(utility.getRestFullUrl(this.resourceName, aggregationUrl), {
      params: {
        tenderGuid,
        year,
        month
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g28Service = new G28Service(resourceName)

export default g28Service
